<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="group relative no-underline cursor-pointer -left-2 inline-flex items-center gap-1 rounded py-1 pl-3 pr-2 text-sm font-semibold transition hover:bg-gray-50 active:scale-95"
    :class="{
      'hover:!bg-white/20': dark,
    }"
    :to="to"
    type="button"
  >
    <app-icon
      class="transition group-hover:-translate-x-1"
      icon="ph:arrow-left"
      :size="20"
    />
    <slot>
      {{ $t("app.cta.back") }}
    </slot>
  </component>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { RouteLocationRaw } from "#vue-router";

const NuxtLink = resolveComponent("nuxt-link");

defineProps<{
  to?: RouteLocationRaw;
  dark?: boolean;
}>();
</script>
